import { createApp } from 'vue'

import 'ant-design-vue/dist/antd.min.css';

import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import antd from 'ant-design-vue';


const app=createApp(App);
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    next();
});
app.use(antd).use(store).use(router).mount('#Kataick')
