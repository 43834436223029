<template>
  <a-layout>
    <LayoutHeader />
    <IndexContent />
    <LayoutFooter />
  </a-layout>
  <!-- <router-view/> -->
</template>

<script>
import LayoutHeader from '../../components/LayoutHeader.vue';
import IndexContent from '../../components/resources/index/IndexContent.vue';
import LayoutFooter from '../../components/LayoutFooter.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    LayoutHeader,
    IndexContent,
    LayoutFooter,
  },
  setup() {
    // const current = ref(['index']);
    

    return {
      
    };
  },
});
</script>