<template>
    <a-layout-footer>
        <div class="footer-colors"></div>
        <div class="footer-colors colors-shadow"></div>
        <p style="position: relative;margin-top: 50px">本站所有内容均是搜集自互联网，版权归原创者所有，本站只提供web页面服务，并不提供影片资源存储，也不参与录制、上传，仅供网络测试和学习交流所用。<br /><br />建议所有影视爱好者购买正版音像制品或去电影院观看最新大片。如侵犯到您的权益，请及时通知我们，我们将及时处理。</p>
        <p>谨此纪念yyets(人人字幕组)</p>
        <p class="kataick-copyright">Copyright &#169; 2022-2023&nbsp;Kataick.&nbsp;版权所有</p>
    </a-layout-footer>
</template>
<style lang="scss" scoped>
    .ant-layout-footer{
        position: relative;
        padding: 0 0 20px 0;
        background-color: #fff;
        text-align: center;
        overflow: hidden;
        p{
            padding: 0 50px;
        }
        .footer-colors{
            width: 100%;
            height: 4px;
            // margin-bottom: 20px;
            background-color: #fff;
            background-position: 0 0;
            background-repeat: repeat;
            background-image: -webkit-repeating-linear-gradient(to right, #68103b, #68103b 50px, #703f28 50px, #703f28 100px, #a16b13 100px, #a16b13 150px, #a5a02c 150px, #a5a02c 200px, #5e812d 200px, #5e812d 250px, #1c6075 250px, #1c6075 300px, #403161 300px, #403161 350px);
            background-image: -webkit-repeating-linear-gradient(left, #68103b, #68103b 50px, #703f28 50px, #703f28 100px, #a16b13 100px, #a16b13 150px, #a5a02c 150px, #a5a02c 200px, #5e812d 200px, #5e812d 250px, #1c6075 250px, #1c6075 300px, #403161 300px, #403161 350px);
            background-image: repeating-linear-gradient(to right, #68103b, #68103b 50px, #703f28 50px, #703f28 100px, #a16b13 100px, #a16b13 150px, #a5a02c 150px, #a5a02c 200px, #5e812d 200px, #5e812d 250px, #1c6075 250px, #1c6075 300px, #403161 300px, #403161 350px);
            animation: animate-colors 200s infinite linear;
            animation-play-state: running
        }
        .colors-shadow{
            position: absolute;
            left: 0;
            right: 0;
            top: 4px;
            height: 30px;
        }
        .colors-shadow:after{
            content: '';
            display: block;
            width: 100%;
            height: 31px;
            background-color: rgba(255, 255, 255, .9);
            // background: -webkit-linear-gradient(to bottom, rgba(255, 255, 255, 0.8), #fff);
            // background: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), #fff);
        }
        .kataick-copyright{
            display: inline-block;
            padding: 0.8em 3em;
            margin-bottom: 0;
            color: #999;
            background-color: #f8f8f8;
            border-radius: 5px;
        }
    }
    @keyframes animate-colors{
        0% {
            background-position: 0 0
        }
        100% {
            background-position: 7000px 0
        }
    }
    [data-theme=dark] {
        .ant-layout-footer{
            background-color: #000;
        }
        .colors-shadow:after{
            background-color: rgba(0, 0, 0, .9);
        }
        .kataick-copyright{
            background-color: #101010;
        }
    }
</style>