import { createRouter, createWebHistory } from 'vue-router'
import IndexView from '../views/resources/IndexView.vue'
import SecondaryView from '../views/resources/SecondaryView.vue'
const routes = [
  {
    path: '/',
    name: 'index',
    // redirect: '/',
    component: IndexView,
    meta: { title: '首页', icon: '', affix: true ,
    //requireAuth: true // 添加该字段，表示进入这个路由是需要登录的
    },
  },{
    path: '/search',
    name: 'scindex',
    // redirect: '/',
    component: ()=>import('../views/search/IndexView.vue'),
    meta: { title: '搜索首页', icon: '', affix: true, kataick: 'sc',
    //requireAuth: true // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: '/movie',
    name: 'movie',
    meta: { title: '电影', name: '电影', prev: '首页', index:'首页', affix: true ,},
    // index: '/movies',
    component: SecondaryView,
    // children: [
    //   {
    //     path: '/subject/:id',
    //     name: 'movieid',
    //     meta: { title: '电影名', name: '电影', prev: '电影', index:'首页', affix: true ,},
    //     // index: '/movies',
    //     component: ()=>import('../views/TertiaryView.vue'),
    //   }
    // ]
  },
  {
    path: '/login',
    name: 'login',
    meta: { title: '登录', name: '登录', prev: '首页', index:'首页', affix: true ,},
    // index: '/movies',
    component: ()=>import('../views/resources/LoginView.vue'),
  },
  {
    path: '/me',
    name: 'me',
    meta: { title: '个人中心', name: '个人中心', prev: '登录', index:'首页', affix: true ,},
    // index: '/movies',
    component: ()=>import('../views/resources/PersonalView.vue'),
  },
  {
    path: '/subject/:id',
    name: 'movieid',
    meta: { title: '电影名', name: ' ', prev: '电影', index:'首页', affix: true ,},
    // index: '/movies',
    component: ()=>import('../views/resources/TertiaryView.vue'),
  },
  {
    path: '/download/:id',
    name: 'downloadid',
    meta: { title: '电影名', name: ' ', prev: '电影', index:'首页', affix: true ,},
    // index: '/movies',
    component: ()=>import('../views/resources/DownloadResourcesView.vue'),
  },
  {
    path: '/verify/:type/:verify',
    name: 'verify',
    meta: { title: '验证', name: ' ', prev: '', index:'首页', affix: true ,},
    // index: '/movies',
    component: ()=>import('../views/resources/VerifyView.vue'),
  },
  {
    path: '/tv',
    name: 'tv',
    meta: { title: '电视剧', name: '电视剧', prev: '首页', affix: true ,},
    component: ()=>import('../views/resources/SecondaryView.vue')
  },
  {
    path: '/ustv',
    name: 'ustv',
    meta: { title: '美剧', name: '美剧', prev: '首页', affix: true ,},
    component: ()=>import('../views/resources/SecondaryView.vue')
  },
  {
    path: '/jptv',
    name: 'jptv',
    meta: { title: '日剧', name: '日剧', prev: '首页', affix: true ,},
    component: ()=>import('../views/resources/SecondaryView.vue')
  },
  {
    path: '/imdb',
    name: 'imdb',
    meta: { title: '高分电影', name: '高分电影', prev: '首页', affix: true ,},
    component: ()=>import('../views/resources/SecondaryView.vue')
  },
  {
    path: '/discuss',
    name: 'discuss',
    meta: { title: '留言板', name: '留言板', prev: '首页', affix: true ,},
    component: ()=>import('../views/resources/DiscussView.vue')
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },
  {
    path: '/:pathMatch(.*)*',   // 错误路由
    redirect: '/'   //重定向
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
